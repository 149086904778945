import styled from "styled-components";

const SuccessMessageContainer = styled.div`
  display: flex;
  font-size: 0.7em;
  color: green;
  height: 15px;
  justify-content: center;
  align-items: center;
  margin: 2px 0 2px 0;
`;
export default SuccessMessageContainer;
