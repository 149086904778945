export const contactFormLeftColumn = [
  {
    placeholder: "Nom*",
    name: "name",
  },
  {
    placeholder: "Email*",
    name: "email",
  },
  {
    placeholder: "Téléphone",
    name: "phone",
  },
];

export const contactFormRightColumn = [
  {
    placeholder: "Objet*",
    name: "subject",
  },
  {
    placeholder: "Message*",
    name: "message",
    multiline: true,
    rows: 4,
  },
];
