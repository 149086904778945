import React from "react";
import { LinkedinFilled, GithubOutlined } from "@ant-design/icons";
import logo_jb from "../../assets/logo_jb.png";
import "./footer.css";

const Footer = () => {
  const scrollTo = (id) => {
    let target = document.getElementById(id);
    target && target.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="footer-icons">
          <a
            href="https://fr.linkedin.com/in/joris-brunetti-236b2b254"
            className="footer-link"
            rel="noreferrer"
            target="_blank"
          >
            <LinkedinFilled className="footer-linkedin-icon" />
          </a>
          <a href="https://github.com/Jokizi" className="footer-link" rel="noreferrer" target="_blank">
            <GithubOutlined className="footer-github-icon" />
          </a>
        </div>
        <div>©</div>
        <div className="footer-logo-jb" onClick={() => scrollTo("home")}>
          <img width="100%" height="100%" src={logo_jb} alt="logo de Joris Brunetti" />
        </div>
        <div>2022</div>
      </div>
    </div>
  );
};

export default Footer;
