import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import photo_presentation from "../../assets/photo_presentation.jpeg";
import Button from "../../components/Button/Button";
import cv from "../../assets/cv-jb.pdf";

import "./presentation.css";

const Presentation = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div id="presentation" className="presentation-container">
      <h2 className="presentation-title">Présentation</h2>
      <div className="presentation-content-container">
        <div data-aos="zoom-in" className="presentation-picture">
          <img width="100%" height="100%" src={photo_presentation} alt="Joris Brunetti" />
        </div>
        <div data-aos="flip-left" className="presentation-text">
          <p className="presentation-text-first">Ravi de vous accueillir sur mon site.</p>
          <h3 className="presentation-text-titles">Qui est-ce ?</h3>
          <p>Joris Brunetti : développeur Web.</p>
          <h3 className="presentation-text-titles">Quel est son parcours ?</h3>
          <p className="presentation-text-second">
            Après plusieurs années d'évolution dans le monde du commerce, le désir d'entreprendre un nouveau challenge
            s'est développé.
            <br />
            Passionné par les nouvelles technologies et le numérique, j'ai décidé de plonger dans l'univers du
            développement web.
            <br /> Pour mener à bien ce nouvel objectif et m'épanouir dans ma nouvelle voie professionnelle, j'ai
            commencé par effectuer une formation sur OpenClassRooms, première plateforme européenne de formation en
            ligne.
            <br /> Cette dernière m'a permis d'apprendre différents langages du web ainsi que leurs utilisations à
            travers différents projets (liste disponible dans mon CV).
            <br />À la suite de l'obtention du diplôme, je poursuis l'aventure en réalisant divers projets
            professionnels et personnels, en équipe et en solo, me permettant d'enrichir mes compétences.
          </p>
          <p className="presentation-text-third">Pour plus de détails, je vous invite à visualiser : </p>
          <a className="presentation-link" href={cv} rel="noreferrer" target="_blank">
            <Button title="Mon CV" />
          </a>
        </div>
      </div>
    </div>
  );
};
export default Presentation;
