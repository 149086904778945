import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette, faLaptopCode, faServer } from "@fortawesome/free-solid-svg-icons";
import responsive from "../../assets/responsive.png";
import code from "../../assets/code.jpg";
import storage from "../../assets/storage.jpg";

import "./work.css";

const Work = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="card-work-container">
      <h3 className="card-work-title">Services à votre disposition</h3>
      <div className="card-work-content">
        <div className="card-work-text">
          <h4>Quels sont les avantages d’une présence sur internet ?</h4>
          <p>
            Vous n'êtes pas sans savoir qu'aujourd'hui toute entreprise, association ou organisation a besoin d'avoir sa
            propre identité numérique sur le web.
            <br />
            Que ce soit pour améliorer sa visibilité, son image ou sa compétitivité face à la concurrence, actuellement
            il est indispensable de développer sa présence en ligne.
            <br />
            97 % des consommateurs vont en premier lieu se rendre sur Internet pour trouver un professionnel, quel que
            soit le problème, l'envie ou le besoin et 50 % des recherches en ligne se convertissent en vente !
          </p>
          <p>Un site vitrine, un site e-commerce, ou autre : je saurai trouver la solution adéquate à vos besoins.</p>
          <h4>Alors qu'attendez vous pour vous lancer ?</h4>
          <p>Pour vous aider à répondre, voici mes propositions de services :</p>
          <div data-aos="fade-up" className="card-work-code-container">
            <div className="card-work-code-content">
              <FontAwesomeIcon icon={faPalette} className="card-work-icon" />
              <h5>Web Design</h5>
              <p>
                À l'écoute de vos envies tout comme de vos éxigences, nous validerons ensemble le design de votre site.
              </p>
              <p>
                Pour une meilleure navigation des internautes, votre projet web sera entièrement adapté à tous types de
                formats d'écrans :
              </p>
              <div className="card-work-picture">
                <img width="100%" height="100%" src={responsive} alt="différents formats d'écrans" />
              </div>
              <p>Ordinateurs, tablettes et téléphones portables.</p>
            </div>
          </div>
          <div data-aos="fade-up" className="card-work-code-container">
            <div className="card-work-code-content">
              <FontAwesomeIcon icon={faLaptopCode} className="card-work-icon" />
              <h5>Développement</h5>
              <p>
                Le développement de votre site sera réalisé de À à Z avec des technologies à la pointe, vous
                garantissant un projet créé sur mesure, en adéquation avec vos besoins.
              </p>
              <p>
                Je développerai une application qui vous ressemble. Vous êtes unique votre site se doit de l'être aussi.
              </p>
              <div className="card-work-picture">
                <img width="100%" height="100%" src={code} alt="lignes de code informatique" />
              </div>
              <p>
                Le code sera optimisé pour le référencement naturel, et répondra parfaitement aux normes
                d'accessibilité.
              </p>
            </div>
          </div>
          <div data-aos="fade-up" className="card-work-code-container">
            <div className="card-work-code-content">
              <FontAwesomeIcon icon={faServer} className="card-work-icon" />
              <h5>Hébergement / Maintenance</h5>
              <p>
                La gestion de l'hébergement ainsi que le déploiement d'eventuelles mises à jour en ligne seront réalisés
                par mes soins.
                <br />
                Un contrat de maintenance pourra vous être proposé si votre projet le requiert.
              </p>
              <div className="card-work-picture">
                <img width="100%" height="100%" src={storage} alt="salle d'hébergement de serveur" />
              </div>
              <p>
                Du cahier des charges au déploiement en ligne, vous serez informé de toutes les étapes de réalisation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
