import styled from "styled-components";

export const FormContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const FormTitle = styled.div`
  margin-bottom: 20px;
  @media (max-width: 767px) {
  }
`;

export const FormAllContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0.5em;
    width: 100%;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1140px) {
    flex-direction: column;
    padding: 0.5em 0;
    width: 100%;
  }
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  @media (max-width: 1140px) {
    margin-right: 0;
  }
`;

export const LeftKey = styled.div`
  width: 100%;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  @media (max-width: 1140px) {
    margin-left: 0;
  }
`;

export const RightKey = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const AlertContainer = styled.div`
  height: 5px;
  margin-top: 10px;
  @media (max-width: 767px) {
    height: 20px;
  }
`;
