import React, { useState, useEffect } from "react";
import api from "../../config/api";
import Input from "../Input/Input";
import Button from "../Button/Button";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import SuccessMessage from "../SuccessMessage/SuccesMessage";
import htmlEncoded from "../../helpers/sanitizer";
import { toastTrigger } from "../../helpers/toast";
import validator from "../../helpers/validator";
import { contactFormLeftColumn, contactFormRightColumn } from "../../config/contactForm";

import {
  FormContainer,
  FormTitle,
  InputsContainer,
  LeftColumn,
  LeftKey,
  InputContainer,
  RightColumn,
  RightKey,
  FormAllContent,
  AlertContainer,
} from "./ContactForm.styled";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [formError, setFormError] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (success === "Votre email a été envoyé" || error === "L'envoi de l'email a échoué") {
      setTimeout(() => {
        setSuccess("");
        setError("");
      }, 5000);
    }
  }, [success, error]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({ name: "", email: "", phone: "", subject: "", message: "" });
    setError("");
    setSuccess("");
  };

  const onSend = async () => {
    const currentError = { name: "", email: "", phone: "", subject: "", message: "" };

    if (!validator.alphaValidator(formData.name)) {
      currentError.name = "Votre nom n'est pas valide";
    }
    if (!formData.message) {
      currentError.message = "Message invalide";
    }
    if (!validator.emailValidator(formData.email)) {
      currentError.email = "Votre adresse email doit comporter un @";
    }
    if (formData.phone.length > 0 && !validator.phoneValidator(formData.phone)) {
      currentError.phone = "Votre numéro de téléphone n'est pas valide";
    }
    if (!formData.subject) {
      currentError.subject = "Objet invalide";
    }

    setFormError(currentError);
    if (
      !validator.alphaValidator(formData.name) ||
      !formData.message ||
      !validator.emailValidator(formData.email) ||
      !formData.subject
    ) {
      setError("L'envoi de l'email a échoué");
      toastTrigger("error", "L'envoi de l'email a échoué ⛔️");
    } else {
      try {
        await api.post("/send", {
          name: htmlEncoded(formData.name),
          email: htmlEncoded(formData.email),
          phone: htmlEncoded(formData.phone),
          subject: htmlEncoded(formData.subject),
          message: htmlEncoded(formData.message),
        });
        setFormData({ name: "", email: "", phone: "", subject: "", message: "" });
        setSuccess("Votre email a été envoyé");
        toastTrigger("success", "Votre email a été envoyé 👌🏼");
      } catch (error) {
        setError("L'envoi de l'email a échoué");
        toastTrigger("error", "L'envoi de l'email a échoué ⛔️");
      }
    }
  };

  return (
    <FormContainer>
      <FormTitle>
        Remplissez le formulaire pour m'envoyer un email.
        <br />
        Je vous répondrai dans les plus brefs délais. (* champs obligatoires)
      </FormTitle>
      <FormAllContent>
        <InputsContainer>
          <LeftColumn>
            {contactFormLeftColumn.map(({ label, placeholder, name }, i) => (
              <LeftKey key={i}>
                <InputContainer>
                  <Input
                    label={label}
                    placeholder={placeholder}
                    value={formData[name]}
                    name={name}
                    onChange={onChange}
                  />
                </InputContainer>
                <ErrorMessage message={formError[name]} />
              </LeftKey>
            ))}
          </LeftColumn>
          <RightColumn>
            {contactFormRightColumn.map(({ label, placeholder, name, rows, multiline }, i) => (
              <RightKey key={i}>
                <InputContainer>
                  <Input
                    label={label}
                    placeholder={placeholder}
                    rows={rows}
                    multiline={multiline}
                    value={formData[name]}
                    name={name}
                    onChange={onChange}
                  />
                </InputContainer>
                <ErrorMessage message={formError[name]} />
              </RightKey>
            ))}
          </RightColumn>
        </InputsContainer>
        <InputContainer>
          <Button title="Envoyer" variant="contained" onClick={onSend} />
        </InputContainer>
        <AlertContainer>
          {success ? <SuccessMessage message={success} /> : <>{""}</>}
          {error ? <ErrorMessage message={error} /> : <>{""}</>}
        </AlertContainer>
      </FormAllContent>
    </FormContainer>
  );
};
export default ContactForm;
