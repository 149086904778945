import "./services.css";
import Work from "../../components/Work/Work";
import Skills from "../../components/Skills/Skills";

const Services = () => {
  return (
    <div id="services" className="services-container">
      <h2 className="services-title">Services & Compétences</h2>
      <div className="services-content-container">
        <Work />
        <Skills />
      </div>
    </div>
  );
};
export default Services;
