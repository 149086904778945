import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

import "./home.css";

const Home = () => {
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDelete, setIsDelete] = useState(false);

  const timeoutRef = useRef(null);
  const displayedTextRef = useRef("");

  useEffect(() => {
    const texts = [
      "Joris Brunetti ",
      "développeur passionné",
      "créatif et réactif",
      "pour réaliser vos projets",
      "web & mobile",
    ];
    if (texts?.length !== 0) {
      const isEmptyDisplay = displayedTextRef.current === "";

      const shouldDelayNextProcess = isDelete && displayedTextRef.current.length === texts[textIndex].length;

      displayedTextRef.current = texts[textIndex].substr(0, charIndex);

      timeoutRef.current = setTimeout(
        () => {
          if (isDelete && isEmptyDisplay) {
            setIsDelete(false);
            setTextIndex((prevTextIndex) => (prevTextIndex + 1 < texts.length ? prevTextIndex + 1 : 0));
          } else if (isDelete || displayedTextRef.current === texts[textIndex]) {
            setIsDelete(true);
            setCharIndex((prevSubIndex) => (prevSubIndex !== -1 ? prevSubIndex - 1 : 0));
          } else if (!isDelete && displayedTextRef.current !== texts[textIndex]) {
            setCharIndex((prevCharIndex) => (prevCharIndex + 1 <= texts[textIndex].length ? prevCharIndex + 1 : 0));
          }
        },
        shouldDelayNextProcess ? 1500 : 150 - Math.random() * 100
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [charIndex, textIndex, isDelete]);

  return (
    <div id="home" className="home-container">
      <div className="home-title-container">
        <h1 className="home-big-title">Développement</h1>
        <h2 className="home-title">
          Web <FontAwesomeIcon icon={faCode} className="home-icon-title" /> Mobile
        </h2>
        <h3 className="home-text">
          {displayedTextRef.current}
          <span className="home-slash">|</span>
        </h3>
      </div>
    </div>
  );
};
export default Home;
