import React, { Component } from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import cine from "../../assets/cine-films.png";
import medisimus from "../../assets/medisimus.png";
import crypto from "../../assets/crypto-watcher.png";
import life from "../../assets/life-stories.png";
import food from "../../assets/oh-my-food.png";
import reservia from "../../assets/reservia.png";

import "./caroussel.css";

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div>
        <Slider {...settings}>
          <div className="caroussel-content-1">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={cine} alt="capture d'écran du site ciné films" />
            </div>
            <div>
              <a className="caroussel-link" href="https://cine-films.netlify.app/" rel="noreferrer" target="_blank">
                Visitez <span className="cinefilms">CinéFilms</span>{" "}
                <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
              </a>
            </div>
          </div>
          <div className="caroussel-content-2">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={medisimus} alt="capture d'écran du site Médisimus plus" />
            </div>
            <a className="caroussel-link" href="https://www.medisimusplus.fr/" rel="noreferrer" target="_blank">
              Visitez <span className="medisimus">MédiSimus +</span>{" "}
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
            </a>
          </div>
          <div className="caroussel-content-3">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={crypto} alt="capture d'écran du site crypto watch" />
            </div>
            <a className="caroussel-link" href="https://cryptos-watcher.netlify.app" rel="noreferrer" target="_blank">
              Visitez <span className="cryptowatch">Crypto Watch</span>{" "}
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
            </a>
          </div>
          <div className="caroussel-content-4">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={life} alt="capture d'écran du site life stories" />
            </div>
            <a className="caroussel-link" href="https://lifes-stories.netlify.app/" rel="noreferrer" target="_blank">
              Visitez <span className="lifestories">Life Stories</span>{" "}
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
            </a>
          </div>
          <div className="caroussel-content-5">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={food} alt="capture d'écran du site oh my food" />
            </div>
            <a
              className="caroussel-link"
              href="https://jokizi.github.io/BrunettiJoris_3_26112020/"
              rel="noreferrer"
              target="_blank"
            >
              Visitez <span className="ohmyfood">OhMyFood</span>{" "}
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
            </a>
          </div>
          <div className="caroussel-content-6">
            <div className="caroussel-img">
              <img width="100%" height="100%" src={reservia} alt="capture d'écran du site réservia" />
            </div>
            <a
              className="caroussel-link"
              href="https://jokizi.github.io/BrunettiJoris_2_28102020/"
              rel="noreferrer"
              target="_blank"
            >
              Visitez <span className="reservia">Réservia</span>{" "}
              <FontAwesomeIcon icon={faArrowRightFromBracket} className="" />
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}
