import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import SimpleSlider from "../../components/Caroussel/Caroussel";

import "./portfolio.css";

const Portfolio = () => (
  <div id="portfolio" className="portfolio-container">
    <h2 className="portfolio-big-title">Portfolio</h2>
    <h3 className="portfolio-small-title">
      Professionnels <FontAwesomeIcon icon={faCode} className="portfolio-icon-title" /> Personnels
    </h3>
    <div className="portfolio-content">
      <SimpleSlider />
    </div>
  </div>
);

export default Portfolio;
