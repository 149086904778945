import React from "react";
import CustomInput from "./Input.styled";

const Input = ({
  label,
  name,
  placeholder,
  type,
  value,
  onChange,
  variant,
  rows,
  multiline = false,
  color,
  size,
  fullWidth,
  width = "500px",
}) => {
  return (
    <CustomInput
      label={label}
      name={name}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      variant={variant}
      multiline={multiline}
      rows={rows}
      color={color}
      size={size}
      fullWidth={fullWidth}
      width={width}
    />
  );
};
export default Input;
