import React from "react";
import Header from "./containers/Header/Header";
import Home from "./containers/Home/Home";
import Presentation from "./containers/Presentation/Presentation";
import Services from "./containers/Services/Services";
import Footer from "./containers/Footer/Footer";
import Portfolio from "./containers/Portfolio/Portfolio";
import BackToTopButton from "./components/BackToTopButton/BackToTopButton";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Contact from "./containers/Contact/Contact";

const App = () => {
  return (
    <div className="main-container">
      <Header />
      <main className="main">
        <Home />
        <Presentation />
        <Services />
        <Portfolio />
        <Contact />
        <BackToTopButton />
        <ToastContainer />
      </main>
      <Footer />
    </div>
  );
};

export default App;
