import React, { useState } from "react";
import logo_jb from "../../assets/logo_jb.png";
import "./header.css";

const Header = () => {
  const [showLinks, setShowLinks] = useState(false);

  const handleShowLinks = () => {
    setShowLinks(!showLinks);
  };

  const scrollTo = (id) => {
    let target = document.getElementById(id);
    target && target.scrollIntoView({ behavior: "smooth", block: "start" });
    setShowLinks(false);
  };

  return (
    <nav className={`header-container ${showLinks ? "show-nav" : "hide-nav"} `}>
      <div className="header-logo-jb" onClick={() => scrollTo("home")}>
        <img width="100%" height="100%" src={logo_jb} alt="logo de Joris Brunetti" />
      </div>
      <ul className="header-links-container">
        <li className="header-links-li slideInDown-1">
          <button onClick={() => scrollTo("home")} className="header-links-button">
            Accueil
          </button>
        </li>
        <li className="header-links-li slideInDown-2">
          <button onClick={() => scrollTo("presentation")} className="header-links-button">
            Présentation
          </button>
        </li>
        <li className="header-links-li slideInDown-3">
          <button onClick={() => scrollTo("services")} className="header-links-button">
            Services & Compétences
          </button>
        </li>
        <li className="header-links-li slideInDown-4">
          <button onClick={() => scrollTo("portfolio")} className="header-links-button">
            Portfolio
          </button>
        </li>
        <li className="header-links-li slideInDown-5">
          <button onClick={() => scrollTo("contact")} className="header-links-button">
            Contact
          </button>
        </li>
      </ul>
      <button className="navbar-burger" onClick={handleShowLinks}>
        <span className="burger-bar"></span>
      </button>
    </nav>
  );
};
export default Header;
