import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDesktop, faDatabase, faServer, faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import html from "../../assets/html.png";
import css from "../../assets/css.png";
import javascript from "../../assets/javascript.png";
import react from "../../assets/react.png";
import redux from "../../assets/redux.png";
import node from "../../assets/node.png";
import mysql from "../../assets/mysql.png";
import postman from "../../assets/postman.png";
import aws from "../../assets/aws.png";
import serverless from "../../assets/serverless.png";
import git from "../../assets/git.png";
import github from "../../assets/github.png";

import "./skills.css";

const Skills = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="card-skills-container">
      <h3 className="card-skills-title">Compétences pour vous servir</h3>
      <div className="card-skills-case">
        <FontAwesomeIcon icon={faDesktop} className="card-work-icon" />
        <h4>Front-End</h4>
        <div data-aos="flip-left" className="card-skills-logo-line">
          <div className="card-skills-logos-container-1">
            <div data-aos="flip-left" className="card-skills-logo-html">
              <img width="100%" height="100%" src={html} alt="logo html 5" />
            </div>
            <div data-aos="flip-left" className="card-skills-logo-css">
              <img width="100%" height="100%" src={css} alt="logo css 3" />
            </div>
            <div data-aos="flip-left" className="card-skills-logo-javascript">
              <img width="100%" height="100%" src={javascript} alt="logo javascript" />
            </div>
          </div>
        </div>
        <div data-aos="flip-left" className="card-skills-logo-line">
          <div className="card-skills-logos-container-2">
            <div data-aos="flip-left" className="card-skills-logo-react">
              <img width="100%" height="100%" src={react} alt="logo react" />
            </div>
            <div data-aos="flip-left" className="card-skills-logo-redux">
              <img width="100%" height="100%" src={redux} alt="logo redux" />
            </div>
          </div>
        </div>
      </div>
      <div className="card-skills-case">
        <FontAwesomeIcon icon={faDatabase} className="card-work-icon" />
        <h4>Back-End</h4>
        <div data-aos="fade-down" className="card-skills-logo-line">
          <div data-aos="zoom-in" className="card-skills-logo-node">
            <img width="100%" height="100%" src={node} alt="logo node js" />
          </div>
        </div>
        <div data-aos="fade-down" className="card-skills-logo-line">
          <div data-aos="zoom-in" className="card-skills-logo-mysql">
            <img width="100%" height="100%" src={mysql} alt="logo mysql" />
          </div>
        </div>
        <div data-aos="fade-down" className="card-skills-logo-line">
          <div data-aos="zoom-in" className="card-skills-logo-postman">
            <img width="100%" height="100%" src={postman} alt="logo postman" />
          </div>
        </div>
      </div>
      <div className="card-skills-case">
        <FontAwesomeIcon icon={faServer} className="card-work-icon" />
        <h4>Hébergement</h4>
        <div data-aos="zoom-in" className="card-skills-logo-line">
          <div data-aos="zoom-in" className="card-skills-logo-aws">
            <img width="100%" height="100%" src={aws} alt="logo aws" />
          </div>
        </div>
        <div data-aos="zoom-in" className="card-skills-logo-line">
          <div data-aos="zoom-in" className="card-skills-logo-serverless">
            <img width="100%" height="100%" src={serverless} alt="logo serverless" />
          </div>
        </div>
      </div>
      <div className="card-skills-case">
        <FontAwesomeIcon icon={faCodeBranch} className="card-work-icon" />
        <h4>Versionnage de projet</h4>
        <div data-aos="fade-right" className="card-skills-logo-line">
          <div data-aos="zoom-in-right" className="card-skills-logo-git">
            <img width="100%" height="100%" src={git} alt="logo git" />
          </div>
        </div>
        <div data-aos="fade-left" className="card-skills-logo-line">
          <div data-aos="zoom-in-left" className="card-skills-logo-github">
            <img width="100%" height="100%" src={github} alt="logo github" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Skills;
