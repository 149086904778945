import styled from "styled-components";

const ErrorMessageContainer = styled.div`
  display: flex;
  font-size: 0.7em;
  font-weight: bold;
  color: #e85a5a;
  height: 15px;
  justify-content: center;
  align-items: center;
  margin: 2px 0 2px 0;
`;
export default ErrorMessageContainer;
