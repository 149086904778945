import logo_jb from "../../assets/logo_jb.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../../components/ContactForm/ContactForm";

import "./contact.css";

const Contact = () => {
  const scrollTo = (id) => {
    let target = document.getElementById(id);
    target && target.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <div id="contact" className="contact-container">
      <h2 className="contact-big-title">Contactez Moi</h2>
      <div className="contact-all-content">
        <div className="contact-left-content">
          <p className="contact-left-text">
            Pour toute demande d'information, de devis ou d'offre d'emploi, n'hésitez pas à me contacter par tout moyen
            indiqué ci-dessous.
          </p>
          <div className="contact-left-infos">
            <span className="contact-name-jb">
              <div className="header-logo-jb" onClick={() => scrollTo("home")}>
                <img width="100%" height="100%" src={logo_jb} alt="logo de Joris Brunetti" />
              </div>
            </span>
            Joris Brunetti
          </div>
          <div className="contact-left-infos">
            <FontAwesomeIcon icon={faLocationDot} className="contact-left-icons" />
            73100 Aix-Les-Bains
          </div>
          <div className="contact-left-infos">
            <FontAwesomeIcon icon={faPhone} className="contact-left-icons" />
            06 33 19 97 62
          </div>
          <div className="contact-left-infos">
            <FontAwesomeIcon icon={faEnvelope} className="contact-left-icons" />
            joris.brunetti@zohomail.eu
          </div>
        </div>
        <div className="contact-right-content">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};
export default Contact;
